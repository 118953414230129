import React, { createRef, useEffect } from "react"
import { graphql, navigate } from "gatsby"
// import QRCode from "qrcode"

import Layout from "../components/layout"
import NavBar from "../components/nav_bar"
import { Pagination } from "@material-ui/lab"
import QrCoderdr from "../components/qr_reader"
import SEO from "../components/seo"

import {
  Select,
  FormControl,
  MenuItem,
  Container,
  makeStyles,
  Grid,
  CssBaseline,
  Fade,
  LinearProgress,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core"

import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"

import Alert from "@material-ui/lab/Alert"
import GetAppIcon from "@material-ui/icons/GetApp"
import OfflinePinIcon from "@material-ui/icons/OfflinePin"
import NamesImage from "../../static/kırgız-esmaullah-600.jpg"

const windowGlobal = typeof window !== "undefined" && window

const useStyles = makeStyles((theme) => ({
  ftrmain: {
    backgroundImage: "radial-gradient(circle farthest-side,#00b1d5 2%, #005b7b 70%)",
    backgroundSize: "contain",
    paddingTop: "10em",
    position: "absolute",
    paddingBottom: "2em",
    marginTop: 74,
    width: "100%",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: "-150%",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: -143,
    },
  },
  ftrdiv: {
    textAlign: "center",
  },
  ftrcpyright: {
    color: "#fff",
  },
  ftrSvg: {
    fill: "#fff",
  },
  svgContainerParent: {
    margin: "0 auto",
    height: "auto",
    textAlign: "center",
    width: 126,
    position: "absolute",
    zIndex: 2,
    left: "50%",
    bottom: 110,
    transform: "translate(-50%)",
    [theme.breakpoints.up("sm")]: {
      bottom: "57.3%",
    },
  },
  breadcrumbsPages: {
    backgroundColor: "rgba(255, 255, 255, 0.38)",
    width: "100%",
    padding: "10px 24px",
  },
  pageInnerContainer: {
    maxWidth: 1400,
  },
  allahNamesImage: {
    maxWidth: "100%",
  },
  heroButtons: {
    marginTop: 12,
  },
  pageContainer: {
    position: "relative",
    paddingBottom: "460px",
  },
  audioPlayerContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
}))

var played = false

const AllahNames = ({ data }) => {
  const classes = useStyles()
  const [errorPlayy, setErrorPlayy] = React.useState("")

  let lclTrans = ""
  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem("lastTrans")) {
      lclTrans = windowGlobal.localStorage.getItem("lastTrans")
    } else {
      lclTrans = "arabic"
      windowGlobal.localStorage.setItem("lastTrans", lclTrans)
    }
  }
  let lclLang = ""

  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem("language")) {
      lclLang = windowGlobal.localStorage.getItem("language")
    } else {
      lclLang = navigator.language.substring(0, 2)
      if (dataNew[lclLang] !== undefined) {
        windowGlobal.localStorage.setItem("language", lclLang)
      } else {
        lclLang = "ar"
        windowGlobal.localStorage.setItem("language", lclLang)
      }
    }
  }

  const [trans, setTrans] = React.useState(lclTrans)

  const langs = {}
  for (const value of data.allStrapiLangs.nodes) {
    langs[value.lang] = value
  }

  const [lang, setLang] = React.useState(lclLang)

  const locale = {}
  for (const value of data.allStrapiLocale.nodes) {
    let textObj = {}
    for (const value2 of value.text) {
      textObj[value2.lang.lang] = value2.text
    }
    locale[value.original] = textObj
  }
  const changeLang = (lang) => {
    setLang(lang)
    windowGlobal.localStorage.setItem("language", lang)
  }

  const dataNew =
    {
      ar: {
        lang: {
          lang: "ar",
          lang_name: "العربية",
          flag: {
            publicURL: "/static/6d497f1cbc288f9cd189720c1797c7a1/b26fb0f8543c49dbde523ccb4e05daf2.svg",
          },
        },
      },
      en: {
        lang: {
          lang: "en",
          lang_name: "english",
          flag: {
            publicURL: "/static/50162f60f2cd5ba81c94bbd8ec32dee0/7e32e549547a7be8844d96a3ae7fe1fe.svg",
          },
        },
      },
      ky: {
        lang: {
          lang: "ky",
          lang_name: "Кыргызча",
          flag: {
            publicURL: "/static/c08e9251ab996b005655d6b5c56f4115/868fdd37402d255581fb4dd32a40d4a9.svg",
          },
        },
      },
      tr: {
        lang: {
          lang: "tr",
          lang_name: "Türkçe",
          flag: {
            publicURL: "/static/657ea685af5d36cee81415a10303e5bb/7987ebc19e150c5dce1274f95a5ac078.svg",
          },
        },
      },
    }

  let dir = "ltr"
  if (lang) {
    dir = lang === "ar" ? "rtl" : "ltr"
  } else {
    dir = lclLang === "ar" ? "rtl" : "ltr"
  }

  const direction = dir === "rtl" ? "rtl" : "ltr"
  const readerImgRtl = dir === "rtl" ? "readerImgRtl" : "readerImgLtr"
  const langNameReader = dir === "rtl" ? "langNameReaderRtl" : "langNameReaderltr"
  const NameReader = dir === "rtl" ? "NameReaderRtl" : "NameReaderltr"
  const svgImg = dir === "rtl" ? "svgImgRtl" : "svgImgltr"
  const readerFormControl = dir === "rtl" ? "readerFormControlRtl" : "readerFormControlLtr"

  const player = createRef()

  if (windowGlobal) {
    windowGlobal.onunload = function() {
      windowGlobal.localStorage.setItem("lastTime", player.current.audio.currentTime)
      windowGlobal.localStorage.setItem("lastTrans", trans)
    }
  }

  const handleErrorPlay = (event) => {
    setErrorPlayy("true")
  }

  const goToTime = () => {
    if (windowGlobal && !played) {
      const lastTime = windowGlobal.localStorage.getItem("lastTime")
      const lastUrl = windowGlobal.localStorage.getItem("lastUrl")
      if (lastTime) {
        player.current.audio.currentTime = lastTime
        played = true
      }
    }
  }

  return (
    <Layout lang={lang} onLangChange={changeLang} locale={locale} dataNew={dataNew} className={classes.layoutContainer}>
      <CssBaseline />
      {/* <Layout
      lang={lang}
      onLangChange={changeLang}
      locale={locale}
      dataNew={dataNew}
      siteUrl={data.site.siteMetadata.siteUrl}
      className={classes.layoutContainer}
    > */}
      <SEO title={"Аллахтын эң сонун ысымдары"} lang={"ky"} />
      <CssBaseline />
      <div className={classes.pageContainer}>
        <Container maxWidth={false} className={classes.pageInnerContainer}>
          <div className={`${classes[direction]} ${classes.heroButtons}`}>
            <div className={classes.audioPlayerContainer}>
              {lclLang == "ky" ?
              <AudioPlayer
                src={"/media/allah-names/ky/allah-names-kg.mp3"}
                rounded={true}
                autoPlay
                preload="metadata"
                ref={player}
                onError={handleErrorPlay}
                onCanPlay={goToTime}
              />
               : <p>File Not Found for this reader or in this lang !</p> }
              {errorPlayy === "true" && (
                <Alert className="dir" severity="error">
                  {locale["audio-file-alert"][lang]}
                </Alert>
              )}
            </div>
            <img src={NamesImage} alt="Аллахтын эң сонун ысымдары" className={classes.allahNamesImage} />
          </div>
        </Container>
        <footer className={`${classes.ftrmain} ftrmain`}>
          <div className={classes.svgContainerParent}>
            <QrCoderdr />
          </div>
          <Box className={classes.ftrdivmain}>
            <Box className={classes.ftrdiv}>
              <Typography
                className={classes.ftrcpyright}
                component="span"
                variant="subtitle2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Tecvitli kuran. All Rights Reserved{" "}
                <svg
                  className={classes.ftrSvg}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  style={{ verticalAlign: "middle" }}
                >
                  <path d="M10.08 10.86c.05-.33.16-.62.3-.87s.34-.46.59-.62c.24-.15.54-.22.91-.23.23.01.44.05.63.13.2.09.38.21.52.36s.25.33.34.53.13.42.14.64h1.79c-.02-.47-.11-.9-.28-1.29s-.4-.73-.7-1.01-.66-.5-1.08-.66-.88-.23-1.39-.23c-.65 0-1.22.11-1.7.34s-.88.53-1.2.92-.56.84-.71 1.36S8 11.29 8 11.87v.27c0 .58.08 1.12.23 1.64s.39.97.71 1.35.72.69 1.2.91 1.05.34 1.7.34c.47 0 .91-.08 1.32-.23s.77-.36 1.08-.63.56-.58.74-.94.29-.74.3-1.15h-1.79c-.01.21-.06.4-.15.58s-.21.33-.36.46-.32.23-.52.3c-.19.07-.39.09-.6.1-.36-.01-.66-.08-.89-.23-.25-.16-.45-.37-.59-.62s-.25-.55-.3-.88-.08-.67-.08-1v-.27c0-.35.03-.68.08-1.01zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>{" "}
                {new Date().getFullYear()}
              </Typography>
            </Box>
          </Box>
        </footer>
      </div>
    </Layout>
  )
}
export default AllahNames

export const query = graphql`
  query AllahNames {
    site {
      siteMetadata {
        quranSiteUrl
        siteUrl
      }
    }
    allStrapiLocale {
      nodes {
        original
        text {
          lang {
            lang
          }
          text
        }
      }
    }
    allStrapiLangs {
      nodes {
        lang
        lang_name
        flag {
          publicURL
        }
      }
    }
  }
`
